@import url("https://fonts.googleapis.com/css?family=Open+Sans:300&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400&display=swap");
body {
  margin: 0;
  font-family: "Roboto", "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #fafafa; */
  /* background: #1f1f1f; */
  background: #181818;
  color: rgb(189, 189, 189);
}

html {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: flex;  
}


.brand-logo {
  color: #338075;
  font-weight: 300;
  margin-bottom: 0;
}

.container {
  margin: 0 auto;
  width: 90%;
}

.content {
  min-height: 30vh;
  margin-top: 48px;
  margin-bottom: 48px;
}

.content > p {
  font-size: 1.25rem;
  font-weight: 300;
}

/* icons */
.personIcon {
  color: #238074;
}

.experienceIcon {
  color: #3cbcd4;
}

.projectsIcon {
  color: #454fb4;
}

.skillsIcon {
  color: #9820ae;
}

.educationIcon {
  color: #eb402f;
}

.contactIcon {
  color: #f89800;
}

.noStyle {
  text-decoration: none;
  display: flex;
  color: inherit;
}

.column {
  flex-direction: column;
}

.h2 {
  margin-top: 0;
}

.landingContainer {
  height: 100vh;
  color: white;
  font-size: 2.5rem;

}

.landingContent {
  color: white;
  margin: 0 auto;
  padding-top: 7rem;
  width: 65%;
  text-shadow: 0 2px 8px black;
}


.landingContent span {
  color: #338075;
}

.landingContent div {
  text-decoration: underline;
  text-decoration-color: #338075;
}

.landingContent div a{
  text-decoration: underline;
  text-decoration-color: #338075;
}

.landingBackground::before {
  content: "";
  display: block;
  opacity: 0.5;
  filter: saturate(1) brightness(0.3);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-image: url("./images/desk_compressed.jpg");
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: -1;
}


/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .landingContainer {
    font-size: 3.5rem;
  }
  .landingContent {
    margin: 0rem 0 0 7rem;
  }
  .content > p {
    font-size: 1.5rem;
    font-weight: 300;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .landingContainer {
    font-size: 4rem;
  }

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .landingContainer {
    font-size: 5rem;
  }
  /* .container {
    width: 80%;
  } */
}

@media (min-width: 2200px) {
  .container {
    width: 60%;
  }
}
